export enum QuoteStatuses {
  CREATE = 1,
  OPEN = 2,
  CLOSED = 3,
  CANCELED = 4,
  WAITING = 5,
  APPROVED = 6,
  CANCELED_IRRELEVANT = 7,
  CANCELED_PRICE = 8,
  CANCELED_DELIVERY_TIME = 9,
  CANCELED_OTHER = 10,
  APPROVED_BY_MANAGER = 11,
  REJECTED_BY_MANAGER = 12,
  PARTIAL_CLOSED = 13,
  WAIT_FOR_PRINT_HOUSE_CONFIRM = 14,
}
